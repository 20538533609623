<template>
  <div class="all">
    <div class="header_tab">
      <van-tabs v-model="active" :ellipsis='true' :swipeable="true">
        <van-tab :title="item.title" v-for="(item,index) in getCategory" :key="index">
          <ul class="classify_list">
            <li class="classify_del" v-for="(subitem,index) in item.children" :key="index" @click="get_del(subitem)">
              <img :src="subitem.image" alt class="shop_img">
              <span class="classify_title">{{subitem.title}}</span>
            </li>
            <li class="classify_del" v-if="item.children.length%3!=0" v-for="(subitem,index) in 3-item.children.length%3" :key="`a`+index" ></li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import { getCategory } from "@/api/shop";
import { Lazyload, Tab, Tabs } from "vant";

Vue.use(Lazyload)
  .use(Tab)
  .use(Tabs);

export default {
  name: "Classify",
  components: {},
  data() {
    return {
      active: 0,
      getCategory:[],
    };
  },
  mounted() {
		this.getClassify()
  },
  methods: {
    getClassify(){
      getCategory().then((res)=>{
        if(res.errCode==0){
          this.getCategory=res.data
        }
      })
    },
    get_del(subitem){
      this.$router.push({
        path: "/GoodsRecommend",
        query: { keyword: subitem.title }
      });
    }
  }
};
</script>

<style scoped>
.header_tab {
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(218,218,218,0.20);
}
.header_tab >>> .van-tab{
  font-size: 16px;
  color: #4d4d4d;
  padding: 0 13px;
  flex-basis:auto !important;
   /* flex: auto !important;
  padding: 0 50px 0 0; */
}
.header_tab >>> .van-tabs__line {
  z-index: 1;
  top:38px;
  bottom: 8px;
  height: 4px;
  /* width: 28px !important; */
  position: absolute;
  border-radius: 2px;
  background: #ffd17c;
}
.van-tabs >>> .van-hairline--top-bottom::after {
  border: none;
}
.header_tab >>> .van-tab--active {
  font-size: 16px;
  color: #ff9393;
  flex-basis:initial;
}
.header_tab >>> .van-tabs__wrap {
  height: 70px;
  padding: 14px 26px;
}
.header_tab >>> span.van-ellipsis {
  /* overflow: visible !important; */
  text-overflow: inherit;
}
.classify_list {
  margin: 12px 14px 0 15px;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  /* background: #f8f8f8; */
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(218, 218, 218, 0.3);
  border-radius: 8px;
  
}
.classify_del {
  width: 115.3px;
    /* background: #fff; */
    
  /* flex: 1; */
  display: flex;
  flex-flow: column;
  align-items: center;
  /* box-shadow: 0 4px 4px 0 rgba(218, 218, 218, 0.3); */
  /* border-radius: 0 8px 0 0; */
  /* margin-right: 2px; */
  border-top: 2px solid rgba(218,218,218,.3);
  border-right: 2px solid rgba(218,218,218,.3);
}
.classify_list li:nth-last-child(1){
  border-bottom:0;
}
.classify_list li:nth-last-child(2){
  border-bottom:0;
}
.classify_list li:nth-last-child(3){
  border-bottom:0;
}
/* .classify_list li:nth-child(1){
  border-radius: 8px 0 0 0;
}
.classify_list li:nth-child(3){
  border-radius: 0 8px 0 0;
} */
.classify_list li:nth-child(3n) {
  /* margin-right: 0; */
  border-right:none;
}
/* .classify_list li:nth-last-child(3) {
  border-bottom: 0;
} */
/* .classify_list li:nth-last-child(2) {
   border-bottom:0;
}
.classify_list li:nth-last-child(1) {
  border-bottom:0;
} */
.classify_list li:nth-child(1){
  border-top:0;
}
.classify_list li:nth-child(2){
  border-top:0;
}
.classify_list li:nth-child(3){
  border-top:0;
}
.shop_img {
  /* width: 67px; */
  height: 54px;
  margin: 22px 23px 12px 24px;
}
.classify_title {
  font-size: 12px;
  color: #666;
  margin-bottom: 6px;
}
</style>
